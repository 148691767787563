import { Grid, Avatar } from "@mui/material";
import '../index.css'
import '../styles/fonts.css';
import SosIcon from '@mui/icons-material/Sos'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOffIcon from '@mui/icons-material/LocationOff';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const styles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#e60e02",
    color: "#FFFFFF",
    padding: 50,
    paddingTop: 100
  },
  title: {
    display: "flex",
    fontSize: '82px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  col: {
    display: "flex",
    justifyContent: 'center',
    margin: 5,
    textAlign: 'center',
    fontSize: '24px',
    border: 'solid 0.5px #FFFFFF',
    borderRadius: 10,
    padding: 100
  },
  avatar: {
    backgroundColor: 'white',
    width: '150px', // Adjust the size as needed
    height: '150px', // Adjust the size as needed
  }
};

const mobileStyles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#e60e02",
    color: "#FFFFFF",
    padding: 50,
    paddingTop: 50
  },
  title: {
    display: "flex",
    fontSize: '30px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  col: {
    display: "flex",
    justifyContent: 'center',
    margin: 5,
    textAlign: 'center',
    fontSize: '24px',
    border: 'solid 0.5px #FFFFFF',
    borderRadius: 10,
    padding: 50,
    marginBottom: 50
  },
  avatar: {
    backgroundColor: 'white',
    width: '150px', // Adjust the size as needed
    height: '150px', // Adjust the size as needed
  }
}


function checkViewport() {
  const elementsToAnimate = document.querySelectorAll(".animate-down");

  elementsToAnimate.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (rect.bottom <= viewportHeight + 250) {
      element.classList.add("animate-in");
    } else {
      element.classList.remove("animate-in");
    }
  });
}

// Attach the checkViewport function to the scroll event
window.addEventListener("scroll", checkViewport);

function ReasonsSection() {
  return (
    !isMobile ?
      <Grid container style={styles.container}>
        <Grid item xs={12} style={styles.title} className="animate-down">
          Why you need it
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 50 }}>
          <Grid container direction={"row"} style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={3.5} style={styles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={styles.avatar}>
                    <SosIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  Fast Alert offers you peace of mind, knowing that help is just a button press away
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3.5} style={styles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={styles.avatar}>
                    <FavoriteBorderIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  In emergencies, every second matters. Fast Alert ensures your loved ones can respond rapidly, potentially saving lives.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3.5} style={styles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={styles.avatar}>
                    <LocationOffIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  We take your privacy seriously. Your location is only shared with the people you trust and only when you need it.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      : <Grid container style={mobileStyles.container}>
        <Grid item xs={12} style={mobileStyles.title} className="animate-down">
          Why you need it
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 50 }}>
          <Grid container direction={"row"} style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={12} style={mobileStyles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={mobileStyles.avatar}>
                    <SosIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  Fast Alert offers you peace of mind, knowing that help is just a button press away
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={mobileStyles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={mobileStyles.avatar}>
                    <FavoriteBorderIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  In emergencies, every second matters. Fast Alert ensures your loved ones can respond rapidly, potentially saving lives.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={mobileStyles.col}>
              <Grid container direction={"column"} className="animate-down">
                <Grid item style={{ paddingBottom: 50, display: "flex", justifyContent: "center" }}>
                  <Avatar style={mobileStyles.avatar}>
                    <LocationOffIcon style={{ color: '#e60e02', fontSize: '70px' }} />
                  </Avatar>
                </Grid>
                <Grid>
                  We take your privacy seriously. Your location is only shared with the people you trust and only when you need it.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default ReasonsSection;