import './App.css';
import { Grid } from '@mui/material';
import FastAlertSection from './Components/FastAlertSection';
import AboutSection from './Components/AboutSection';
import ReasonsSection from './Components/ReasonsSection';
import WhoSection from './Components/WhoSection';
import ContactSection from './Components/ContactSection';

function App() {
  return (
    <Grid>
      <FastAlertSection />
      <AboutSection />
      <ReasonsSection />
      <WhoSection />
      <ContactSection />
    </Grid>
  );
}

export default App;
