import { Grid } from "@mui/material";
import '../index.css'
import '../styles/fonts.css';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const styles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#FFFFFF",
    padding: 50,
    paddingTop: 100,
    paddingBottom: 100
  },
  title: {
    fontSize: '82px',
    color: '#000000',
    fontWeight: 'bold'
  },
  text: {
    paddingTop: 50,
    fontSize: '24px'
  },
  image: {
    height: '100%',
    width: '100%'
  }
};

const mobileStyles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#FFFFFF",
    padding: 25,
    paddingTop: 50,
    paddingBottom: 100
  },
  title: {
    fontSize: '45px',
    color: '#000000',
    fontWeight: 'bold'
  },
  text: {
    paddingTop: 50,
    fontSize: '24px'
  },
  image: {
    height: '100%',
    width: '100%'
  }
}

function checkViewport() {
  const elementsToAnimate = document.querySelectorAll(".animate-right");
  const elementsToShow = document.querySelectorAll(".show");

  elementsToAnimate.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (rect.bottom <= viewportHeight) {
      element.classList.add("animate-in");
    } else {
      element.classList.remove("animate-in");
    }
  });

  elementsToShow.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (rect.bottom <= viewportHeight * 1.5) {
      element.classList.add("animate-in");
    } else {
      element.classList.remove("animate-in");
    }
  });
}

// Attach the checkViewport function to the scroll event
window.addEventListener("scroll", checkViewport);

function WhoSection() {
  return (
    !isMobile ?
      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <img
            src="../meeting.jpg"
            alt="People in a meeting"
            style={styles.image}
            className="show"
          />
        </Grid>
        <Grid item xs={6} style={mobileStyles.container}>
          <Grid item xs={12} style={styles.container}>
            <Grid container direction={'column'}>
              <Grid style={styles.title} className="animate-right">
                Who are we
              </Grid>
              <Grid style={styles.text}>
                At Fast Alert, we are driven by a simple yet profound belief that everyone deserves to feel safe and connected, no matter the circumstances.
                Founded with a deep commitment to making the world a safer place,
                we are dedicated to providing individuals with the tools they need to navigate life's uncertainties with confidence.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      : <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <img
            src="../meeting.jpg"
            alt="People in a meeting"
            style={mobileStyles.image}
            className="show"
          />
        </Grid>
        <Grid item xs={12} style={mobileStyles.container}>
          <Grid item xs={12} style={mobileStyles.container}>
            <Grid container direction={'column'}>
              <Grid style={mobileStyles.title} className="animate-right">
                Who are we
              </Grid>
              <Grid style={mobileStyles.text}>
                At Fast Alert, we are driven by a simple yet profound belief that everyone deserves to feel safe and connected, no matter the circumstances.
                Founded with a deep commitment to making the world a safer place,
                we are dedicated to providing individuals with the tools they need to navigate life's uncertainties with confidence.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default WhoSection;