import { Grid } from "@mui/material";
import '../index.css'
import '../styles/fonts.css';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const styles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#FFFFFF",
    padding: 50,
    paddingTop: 100,
    paddingBottom: 100
  },
  title: {
    fontSize: '82px',
    color: '#000000',
    fontWeight: 'bold'
  },
  text: {
    paddingTop: 50,
    fontSize: '24px'
  },
  image: {
    height: '100%',
    width: '100%'
  }
};

const mobileStyles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#FFFFFF",
    padding: 50,
    paddingTop: 100,
    paddingBottom: 100
  },
  title: {
    fontSize: '45px',
    color: '#000000',
    fontWeight: 'bold'
  },
  text: {
    paddingTop: 50,
    fontSize: '24px'
  },
  image: {
    height: '100%',
    width: '100%'
  }
}

function checkViewport() {
  const elementsToAnimate = document.querySelectorAll(".animate-left");
  const elementsToShow = document.querySelectorAll(".show");

  elementsToAnimate.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (rect.bottom <= viewportHeight) {
      element.classList.add("animate-in");
    } else {
      element.classList.remove("animate-in");
    }
  });

  elementsToShow.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (rect.bottom <= viewportHeight * 1.5) {
      element.classList.add("animate-in");
    } else {
      element.classList.remove("animate-in");
    }
  });
}

// Attach the checkViewport function to the scroll event
window.addEventListener("scroll", checkViewport);

function AboutSection() {
  return (
    !isMobile ?
      <Grid container>
        <Grid item xs={6} style={styles.container}>
          <Grid item xs={12} style={styles.container}>
            <Grid container direction={'column'}>
              <Grid style={styles.title} className="animate-left">
                About the app
              </Grid>
              <Grid style={styles.text}>
                Fast Alert is a personal safety app that can help you stay safe in an emergency.
                With just one button press, you can send your location to your friends or family,
                who can then come and help you.
                Fast Alert is easy to use and can be a lifesaver in any situation.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <img
            src="../hand.png"
            alt="hand with app"
            style={styles.image}
            className="show"
          />
        </Grid>
      </Grid>
      : <Grid container direction={'column'}>
        <Grid item xs={12} style={mobileStyles.container}>
          <Grid container direction={'column'}>
            <Grid style={mobileStyles.title} className="animate-left">
              About the app
            </Grid>
            <Grid style={mobileStyles.text}>
              Fast Alert is a personal safety app that can help you stay safe in an emergency.
              With just one button press, you can send your location to your friends or family,
              who can then come and help you.
              Fast Alert is easy to use and can be a lifesaver in any situation.
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <img
            src="../hand.png"
            alt="hand with app"
            style={mobileStyles.image}
            className="show"
          />
        </Grid>
      </Grid>
  )
}

export default AboutSection;