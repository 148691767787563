import { Divider, Grid } from "@mui/material";
import '../index.css'
import '../styles/fonts.css';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const styles = {
    container: {
        fontFamily: 'Open Sans, sans-serif',
        background: "#e60e02",
        color: "#FFFFFF",
        maxWidth: "100vw",
        paddingLeft: 75
    },
    slogan: {
        fontSize: '150px',
        display: 'flex',
        alignItems: 'center',
        height: '500px',
        marginBottom: '-150px',
        textAlign: 'center'
    },
    image: {
        height: 125
    },
    smartphone: {
        height: 600,
        paddingBottom: 100
    },
    googlePlay: {
        height: 100
    }
};

const mobileStyles = {
    container: {
        fontFamily: 'Open Sans, sans-serif',
        background: "#e60e01",
        color: "#FFFFFF",
        maxWidth: "100vw",
        paddingLeft: 15
    },
    slogan: {
        fontSize: '60px',
        display: 'flex',
        alignItems: 'center',
        height: '200px',
        marginBottom: '-50px',
        textAlign: 'center'
    },
    image: {
        height: 75
    },
    smartphone: {
        height: 400,
        paddingBottom: 100
    },
    googlePlay: {
        height: 50
    }
}

function checkViewport() {
    const elementsToAnimateLeft = document.querySelectorAll(".animate-left");
    const elementsToAnimateRight = document.querySelectorAll(".animate-right");
    const elementsToAnimateDown = document.querySelectorAll(".animate-down");

    elementsToAnimateLeft.forEach((element) => {
        if (!element.classList.contains("animated")) {
            const rect = element.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top >= 0 && rect.bottom <= viewportHeight) {
                element.classList.add("animate-in", "animated");
            }
        }
    });

    elementsToAnimateRight.forEach((element) => {
        if (!element.classList.contains("animated")) {
            const rect = element.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top >= 0 && rect.bottom <= viewportHeight) {
                element.classList.add("animate-in", "animated");
            }
        }
    });

    elementsToAnimateDown.forEach((element) => {
        if (!element.classList.contains("animated")) {
            const rect = element.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top >= 0 && rect.bottom <= viewportHeight) {
                element.classList.add("animate-in", "animated");
            }
        }
    });
}

// Attach the checkViewport function to the scroll event
window.addEventListener("load", checkViewport);

document.addEventListener("DOMContentLoaded", checkViewport);

function FastAlertSection() {
    return (
        !isMobile ?
            <Grid item xs={12} style={styles.container}>
                <Grid container style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Grid item xs={7} style={{ paddingTop: 175 }}>
                        <Grid style={styles.slogan} className="animate-left">
                            Fast Alert
                        </Grid>
                        <Grid>
                            <img
                                src="../transparantLogo.png"
                                alt="Fast Alert Logo"
                                style={styles.image}
                            />
                        </Grid>
                        <Grid item xs={11} style={{ height: 100 }}>
                            <Divider sx={{ backgroundColor: '#FFFFFF', borderWidth: 1 }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ paddingTop: 50, position: "absolute", right: '10%' }}>
                        <Grid container>
                            <Grid>
                                <img
                                    src="../smartphone.png"
                                    alt="Fast Alert Logo"
                                    style={styles.smartphone}
                                />
                            </Grid>
                            <Grid style={{ position: "absolute", top: 500, left: 300 }} className="animate-right">
                                <a href='https://play.google.com/store/apps/details?id=com.fastalert.fastalert&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                        style={styles.googlePlay}
                                    />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            : <Grid item xs={12} style={mobileStyles.container}>
                <Grid container style={{ display: "flex", justifyContent: "flex-start" }} direction={'column'}>
                    <Grid item xs={12}>
                        <Grid style={mobileStyles.slogan}>
                            Fast Alert
                        </Grid>
                        <Grid>
                            <img
                                src="../transparantLogo.png"
                                alt="Fast Alert Logo"
                                style={mobileStyles.image}
                            />
                        </Grid>
                        <Grid item xs={11} style={{ height: 100 }}>
                            <Divider sx={{ backgroundColor: '#FFFFFF', borderWidth: 1 }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid>
                                <img
                                    src="../smartphone.png"
                                    alt="Fast Alert Logo"
                                    style={mobileStyles.smartphone}
                                    className="animate-down"
                                />
                            </Grid>
                            <Grid style={{ position: "absolute", top: 600, left: 200 }} className="animate-right">
                                <a href='https://play.google.com/store/apps/details?id=com.fastalert.fastalert&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                        style={mobileStyles.googlePlay}
                                    />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default FastAlertSection;