import { Grid } from "@mui/material";
import '../index.css'
import '../styles/fonts.css';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const styles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#e60e02",
    color: "#FFFFFF"
  },
  title: {
    display: "flex",
    fontSize: '82px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    justifyContent: 'left',
    paddingBottom: 30
  },
  textHead: {
    fontSize: '30px',
    fontWeight: 'bold',
    paddingRight: 80
  },
  text: {
    fontSize: '20px',
    color: '#FFFFFF',
    textDecoration: 'none'
  },
  groups: {
    border: 'solid 0.25px #FFFFFF',
    borderRadius: 100,
    padding: 20,
    paddingLeft: 40,
    display: 'flex',
    alignItems: 'center',
    margin: 10
  },
  image: {
    height: '100%',
    width: '100%'
  }
};

const mobileStyles = {
  container: {
    fontFamily: 'Open Sans, sans-serif',
    background: "#e60e02",
    color: "#FFFFFF"
  },
  title: {
    display: "flex",
    fontSize: '45px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    justifyContent: 'left'
  },
  textHead: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingRight: 10
  },
  text: {
    fontSize: '16px',
    color: '#FFFFFF',
    textDecoration: 'none'
  },
  groups: {
    border: 'solid 0.25px #FFFFFF',
    borderRadius: 100,
    padding: 20,
    paddingLeft: 40,
    display: 'flex',
    alignItems: 'center',
    margin: 10
  },
  image: {
    height: '100%',
    width: '100%'
  }
}

function checkViewport() {
  const elementsToAnimateLeft = document.querySelectorAll(".animate-left");
  const elementsToShow = document.querySelectorAll(".show");

  elementsToAnimateLeft.forEach((element) => {
    if (!element.classList.contains("animated")) {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top >= 0 && rect.bottom <= viewportHeight) {
        element.classList.add("animate-in", "animated");
      }
    }
  });

  elementsToShow.forEach((element) => {
    if (!element.classList.contains("animated")) {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.bottom >= 0 && rect.bottom <= viewportHeight * 1.5) {
        element.classList.add("animate-in", "animated");
      }
    }
  });
}

// Attach the checkViewport function to the scroll event
window.addEventListener("scroll", checkViewport);

function ContactSection() {
  return (
    !isMobile ? 
    <Grid container style={styles.container} direction={"row"}>
      <Grid item xs={6} style={{paddingLeft: 75, paddingTop: 100, paddingBottom: 150}}>
        <Grid item xs={12} style={styles.title} className="animate-left">
          Contact
        </Grid>
        <Grid item xs={9}>
          <Grid container direction={"column"} className="animate-left">
            <Grid container direction={"row"} style={styles.groups} >
              <Grid style={styles.textHead}>
                EMAIL
              </Grid>
              <Grid>
                <a href="mailto:buymatch1@gmail.com" style={styles.text} >
                  buymatch1@gmail.com
                </a>
              </Grid>
            </Grid>
            <Grid container direction={"row"} style={styles.groups} >
              <Grid style={styles.textHead}>
                SOCIAL
              </Grid>
              <Grid>
                <a href="https://www.facebook.com/profile.php?id=100086515930665" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                  <img src="../facebook.png" alt="Facebook icon" height="30" />
                </a>
                <a href="https://www.instagram.com/fast.alert/" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                  <img src="../instagram.png" alt="Instagram icon" height="30" />
                </a>
                <a href="https://twitter.com/thefastalert" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                  <img src="../twitter.png" alt="Twitter icon" height="30" />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
        <img
          src="../working.jpg"
          alt="People working"
          style={styles.image}
          className="show"
        />
      </Grid>
    </Grid>
    : <Grid container style={mobileStyles.container} direction={"row"}>
    <Grid item xs={12} style={{paddingLeft: 25, paddingTop: 50, paddingBottom: 50}}>
      <Grid item xs={12} style={mobileStyles.title} className="animate-left">
        Contact
      </Grid>
      <Grid item xs={10}>
        <Grid container direction={"column"} className="animate-left">
          <Grid container direction={"row"} style={mobileStyles.groups} >
            <Grid style={mobileStyles.textHead}>
              EMAIL
            </Grid>
            <Grid>
              <a href="mailto:buymatch1@gmail.com" style={mobileStyles.text} >
                buymatch1@gmail.com
              </a>
            </Grid>
          </Grid>
          <Grid container direction={"row"} style={styles.groups} >
            <Grid style={mobileStyles.textHead}>
              SOCIAL
            </Grid>
            <Grid>
              <a href="https://www.facebook.com/profile.php?id=100086515930665" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                <img src="../facebook.png" alt="Facebook icon" height="25" />
              </a>
              <a href="https://www.instagram.com/fast.alert/" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                <img src="../instagram.png" alt="Instagram icon" height="25" />
              </a>
              <a href="https://twitter.com/thefastalert" target="_blank" rel="noopener noreferrer" style={{ padding: 10 }}>
                <img src="../twitter.png" alt="Twitter icon" height="25" />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
      <img
        src="../working.jpg"
        alt="People working"
        style={mobileStyles.image}
        className="show"
      />
    </Grid>
  </Grid>
  )
}

export default ContactSection;